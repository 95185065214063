<template>
  <b-modal
    ref="modal"

    :title="(options && options.title || 'Add remark')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <form class="form" @submit.prevent="submitForm">
        <div class="form-group mb-0">
          <label>Remark <sup>Required</sup></label>
          <textarea
            ref="textarea:remark"

            :class="['form-control']"
            v-model="form.remark"

            rows="10"
            cols="7"
          />
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" class="btn btn-success" :disabled="isLoadingForm" @click.prevent="submitForm">Submit</button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

export default {
  mixins: [Mixins.Modal],
  name: 'remarkModal',
  data () {
    return {
      options: null,
      isLoadingForm: false,

      form: {
        remark: null,
      },
    };
  },
  validations: {
    form: {
      remark: {
        required,
      },
    },
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:equipment:remark:open', this.open);
    this.$eventhub.on('modals:equipment:remark:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:equipment:remark:open', this.open);
    this.$eventhub.off('modals:equipment:remark:close', this.close);
  },

  methods: {
    async validate () {
      await validations.validateArray([this.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.form);
        }
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { ui_element: true });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      // Reset options
      this.$set(this, 'options', options);

      // Reset form
      this.$set(this.form, 'remark', null);
      this.$v.$reset();

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    onShown () {
      this.$nextTick(() => {
        this.$refs['textarea:remark'].focus();
      });
    },
  },
};
</script>
