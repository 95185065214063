<template>
  <div class="row">
    <div class="col">
      <!-- begin::Datatable actions -->
      <div class="mb-7">
        <div class="row align-items-center">
          <button
            v-if="$auth.hasPermission('equipment_remarks.create')"
            class="btn btn-success font-weight-bolder ml-auto"
            @click.prevent="createEquipmentRemark"
          >
            <span>Add remark</span>
          </button>
        </div>
      </div>
      <!-- end::Datatable actions -->

      <datatable
        ref="datatable"
        :url="datatable.url"
        :fields="datatable.fields"
        :search-fields="datatable.searchFields"
        :sort-order="datatable.sortOrder"
        :on-pagination-data="onPaginationData"

        :transform="transform"
      >
        <!-- begin::Actions -->
        <div slot="actions" slot-scope="props">
          <b-dropdown
            ref="actions-dropdown"
            variant="clean btn-sm btn-icon"
            class="dropdown-inline mr-2"
            right
            lazy
            no-caret
            boundary="window"
            menu-class="dropdown-menu-md"
          >
            <template v-slot:button-content>
              <span class="svg-icon">
                <font-awesome-icon :icon="['fas', 'cog']" />
              </span>
            </template>

            <template v-slot:default>
              <ul class="navi flex-column navi-hover py-2">
                <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                  Choose an action:
                </li>

                <li class="navi-separator my-0" />

                <template v-if="$auth.hasPermission('equipment_remarks.delete')">
                  <li class="navi-item">
                    <a class="navi-link cursor-pointer" @click.prevent="removeEquipmentRemark(props.rowData)">
                      <span class="navi-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'trash']" class="w-15px h-15px" />
                        </i>
                      </span>
                      <span class="navi-text">Delete remark</span>
                    </a>
                  </li>
                </template>
              </ul>
            </template>
          </b-dropdown>
        </div>
        <!-- end::Actions -->
      </datatable>

      <!-- begin::Datatable footer -->
      <div class="datatable datatable-default">
        <div class="datatable-pager datatable-paging-loaded">
          <datatable-pagination
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />

          <datatable-pagination-info
            ref="pagination.info"
            @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
          />
        </div>
      </div>
      <!-- end::Datatable footer -->
    </div>

    <!-- begin::Modals -->
    <remark-modal v-if="$auth.hasPermission('equipment_remarks.create')" />
    <!-- end::Modals -->
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { DatatableMixin } from '@vedicium/metronic-vue';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import Equipment from '@/libs/classes/equipment';
import EquipmentRemark from '@/libs/classes/equipment_remark';

import remarkModal from './modals/remark.modal.vue';

export default {
  mixins: [equipmentMixin, DatatableMixin],
  components: {
    remarkModal,
  },
  data () {
    return {
      datatable: {
        url: `${Equipment.uri}/${this.equipment._meta.guid}/remarks`,

        fields: [{
          name: '_meta.created',
          title: 'Date',
          sortField: '_meta.created',
          titleClass: 'w-200px',
          dataClass: 'w-200px align-top',
          formatter: (value) => moment.utc(value).tz(this.equipment.location.timezone || 'UTC').format('LLL'),
        }, {
          name: 'remark',
          title: 'Remark',
          titleClass: 'w-400px',
          dataClass: 'w-400px align-top text-prewrap',
        }, {
          name: 'resource.name',
          title: 'User',
          sortField: 'resource.name',
          titleClass: 'w-200px',
          dataClass: 'w-200px align-top',
        }, {
          name: 'company.name',
          title: 'Company',
          sortField: 'company.name',
          titleClass: 'w-200px',
          dataClass: 'w-200px align-top',
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-125px',
          dataClass: 'w-125px align-top',
        }].filter((row) => {
          switch (row.name) {
            case 'actions': {
              return this.$auth.hasPermission('equipment_remarks.delete');
            }

            default: {
              return true;
            }
          }
        }),

        sortOrder: [{
          field: '_meta.created',
          direction: 'desc',
        }],

        filters: {},

        searchFields: [],
      },
    };
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new EquipmentRemark().merge(row));
      }
      return response;
    },

    createEquipmentRemark () {
      const vm = this;
      this.$eventhub.emit('modals:equipment:remark:open', {
        title: 'Add remark',
        async onSubmit ({ remark }) {
          await vm.$ws.post(`${Equipment.uri}/${vm.equipment._meta.guid}/remarks`, {
            body: {
              remark,
            },
            query: {
              persistent: true,
            },
          });

          vm.$refs.datatable.refresh();
        },
      });
    },

    removeEquipmentRemark (document = null) {
      if (!document || document instanceof EquipmentRemark === false) {
        return;
      }

      const vm = this;
      vm.$metronic.eventhub.emit('modals:layout:confirmation:open', {
        title: 'Remove remark',
        message: 'Are you sure that you want to remove the remark?',
        async onSubmit () {
          await vm.$ws.delete(`${Equipment.uri}/${vm.equipment._meta.guid}/remarks/${document._meta.guid}`, {
            query: {
              persistent: true,
            },
          });

          vm.$refs.datatable.refresh();
        },
      });
    },
  },
};
</script>
